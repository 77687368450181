import $ from 'jquery';

$(function () {
  const modal_holder_selector = '#modal-holder';
  const modal_selector = '#mainModal';

  $(document).on('click', '.modal .close, .modal [data-dismiss="modal"]', function () {
    $('.modal-backdrop').remove();
  });
  
  $(document).on('click', 'a[data-modal=true]', function () {
    const button = $(this);
    if(button.attr("disabled") == "disabled"){
      return false;
    }
    const button_text = button.prop('innerHTML');
    const location = button.attr('href');
    button.prop('innerHTML', '<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...');
    button.attr('disabled', 'disabled');
    button.addClass('disabled');
    // Load modal dialog from server
    $.get(
      location,
      data => {
        $(modal_holder_selector).html(data).find(modal_selector).modal({ keyboard: false, backdrop: 'static' });
        button.prop('innerHTML', button_text);
        button.attr('disabled', null);
        button.removeClass('disabled');
        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
          form.addEventListener('submit', function (event) {
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            }
            form.classList.add('was-validated');
          }, false);
        });
      }
    );
    return false;
  });

  $(document).on('ajax:success', 'form[data-modal=true]', function (event) {
    const [data, _status, xhr] = event.detail;
    const url = xhr.getResponseHeader('Location');
    if (url == null) {
      // Remove old modal backdrop
      $('.modal-backdrop').remove();
      // Update modal content
      const modal = $(data).find('body').html() || $(data);
      $(modal_holder_selector).html(modal).find(modal_selector).modal({ keyboard: false, backdrop: 'static' });
    } else if (url == "js") {
      $(modal_selector).modal('toggle');
    } else if (url == "js-wait"){

    }
    else {
      // Redirect to url
      // window.location = url;
      //$(".modal-footer [type=button]").click();
      $(modal_selector).modal('toggle');
      // location.reload();
      Turbo.visit(url.toString());
    }
    return false;
  });
});
